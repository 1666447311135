<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="diaryData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.userDiary.errorUser") }}
        <b-link class="alert-link" :to="{ name: 'apps-diary-list' }">
          {{ $t("message.timeSheet.listOfreleases") }}
        </b-link>
        {{ $t("message.userDiary.forOtherDiary") }}
      </div>
    </b-alert>

    <template v-if="diaryData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.userDiary.recordDiary") }}</h2>
          <b-row>
            <b-col md="4">
              <!-- select date-->
              <b-form-group label-for="date">
                <template v-slot:label>
                  {{ $t("message.tableHeader.date") }}
                </template>
                <b-form-datepicker
                  id="created-date"
                  v-model="diaryData.date"
                  class="form-control"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label-for="user">
                <template v-slot:label>
                  {{ $t("message.user.user") }}
                </template>
                <v-select
                  class="mt-0"
                  v-model="diaryData.userId"
                  :options="userOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-id"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label-for="type">
                <template v-slot:label>
                  {{ $t("message.tasks.type") }}
                </template>
                <b-form-input
                  disabled
                  v-model="diaryData.type"
                  input-id="type-id"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="description">
                <template v-slot:label>
                  {{ $t("message.projects.description") }}
                </template>
                <b-alert variant="secondary" show>
                  <div class="alert-body">
                    <span v-html="diaryData.description"></span>
                  </div>
                </b-alert>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_diary')"
                  :to="{
                    name: 'apps-diary-edit',
                    params: { id: diaryData.id },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-if="restrictions('button_delete_diary')"
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <br />
          <b-row>
            <b-col
              style="max-width: 200px; !important"
              v-for="item in filesData"
              :key="item.id"
            >
              <b-card
                text-variant="white"
                class="text-center"
                bg-variant="primary"
              >
                <feather-icon size="40" icon="FileIcon" />

                <div class="truncate">
                  <br />
                  <span>{{ item.name }}</span>
                </div>
                <br />
                <b-row>
                  <b-col md="12"
                    ><b-button
                      size="sm"
                      @click="downloadFile(item.id, item.name)"
                      v-if="restrictions('diary_file_download')"
                      ><feather-icon size="20" icon="DownloadCloudIcon"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar a conta ?
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-diary-list' }"
            @click="deleteDiary()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import download from "downloadjs";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    flatPickr,
    BFormDatepicker,
    vSelect,
  },

  data() {
    return {
      userOptions: [],

      userData: store.state.user.userData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",
    };
  },

  async created() {
    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({ label: userList[i], value: userId[i] });
        }
      });

    await this.getFilesData();
  },

  setup() {
    const diaryData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}diary/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        diaryData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          diaryData.value = undefined;
        }
      });

    return {
      diaryData,
    };
  },

  methods: {
    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    async getFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    deleteDiary() {
      axios({
        method: "delete",
        url: `${URL_API}diary/${this.diaryData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O lançamento foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            diaryData.value = undefined;
          }
        });
    },

    restrictions(value) {
      return AccessControl(value);
    },
  },
};
</script>

<style></style>
